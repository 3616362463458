import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'pure-react-carousel';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class FeaturedItem extends Component {
  render() {
    const { node, position } = this.props;

    return (
      <Slide index={position} className="featured-slider">
        <div className="featured-img-wrapper">
          <div className="featured-img">
            <img
              src={node.featuredImage.fluid.src}
              alt={node.featuredImage.title}
              className="fill-x db"
              id={node.featuredImage.id}
            />
          </div>
          <div className="featured-details">
            <h5>{node.title}</h5>
            {node.description && <p>{node.description.description}</p>}
            <AniLink fade to={node.linkUrl}>
              View more
            </AniLink>
          </div>
        </div>
      </Slide>
    );
  }
}

export default FeaturedItem;

FeaturedItem.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    featuredImage: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    description: PropTypes.object,
    title: PropTypes.string,
    linkUrl: PropTypes.string,
  }).isRequired,
  position: PropTypes.number.isRequired,
};
