import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import BannerItem from './banner-item';
import right from '../assets/icons/right-chevron.svg';
import left from '../assets/icons/left-chevron.svg';

class HeroBanner extends Component {
  render() {
    const { node } = this.props;
    const numberofSlides = node.items.length;

    return (
      <div className="banner-content">
        <div className="hero-banner-overlay" />
        <div className="container hero-title">
          <div className="row">
            <div className="col-12 sm:col-10 md:col-8">
              <h1 className="white">{node.heading}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 sm:col-8 md:col-5">
              <p className="white sub-heading">{node.subheading}</p>
              <AniLink
                fade
                direction="out"
                className="yellow tdu hero-link"
                to={node.linkUrl}
              >
                {node.linkText}
              </AniLink>
            </div>
          </div>
        </div>
        <div className="img-carousel">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={numberofSlides}
            dragEnabled={false}
            touchEnabled={false}
          >
            <Slider className="slider-element">
              {node.items.map(item => {
                return <BannerItem key={item.id} {...item} />;
              })}
            </Slider>
            <div className="indicators">
              <ButtonBack className="prev">
                <img src={left} alt="Previous slide" width="10px" />
              </ButtonBack>
              <ButtonNext className="next">
                <img src={right} alt="Next slide" width="10px" />
              </ButtonNext>
            </div>
          </CarouselProvider>
        </div>
      </div>
    );
  }
}

export default HeroBanner;

HeroBanner.propTypes = {
  node: PropTypes.object.isRequired,
};
