import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'pure-react-carousel';

import quote from '../assets/images/quote.svg';

class TestimonialItem extends Component {
  render() {
    const { node } = this.props;
    let name;

    if (node.company) {
      name = <h4 className="testimonial-name with-company">{node.name}</h4>;
    } else {
      name = <h4 className="testimonial-name m0 mb10">{node.name}</h4>;
    }

    return (
      <Slide className="slide">
        <div className="testimonial-content testimonial">
          <img className="quote-icon" src={quote} alt="quote" width="142" />
          {name}
          {node.company && (
            <h6 className="testimonial-company">{node.company}</h6>
          )}
          <p>{node.testimonial.testimonial}</p>
        </div>
      </Slide>
    );
  }
}

export default TestimonialItem;

TestimonialItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  node: PropTypes.object.isRequired,
};
