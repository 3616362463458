import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import HeroBannerLayout from '../components/hero-banner';
import HomepageServices from '../components/homepage-services';
import FeaturedProjects from '../components/featured-projects';
import ContactForm from '../components/Forms/ContactForm';
import Testimonials from '../components/testimonials';

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const BannerNode = data.allContentfulHeroBanner.edges[0].node;
    const FeaturedEdges = data.allContentfulFeaturedProject.edges;
    const TestimonialEdges = data.allContentfulTestimonial.edges;

    return (
      <Layout>
        <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
        <Header headerType="home-header" />
        <HeroBannerLayout node={BannerNode} />
        <HomepageServices
          serviceImage={data.serviceImage}
          residentialImage={data.residentialImage}
          commercialImage={data.commercialImage}
          specialImage={data.specialImage}
        />
        <FeaturedProjects items={FeaturedEdges} />
        <Testimonials items={TestimonialEdges} />
        <ContactForm
          heading="Start with a free quote!"
          subheading="One our team will be in contact to help you with your request. Obligation free."
          theme="grey"
          map={false}
          formType="home"
        />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const indexQuery = graphql`
  query {
    allContentfulHeroBanner {
      edges {
        node {
          id
          heading
          subheading
          linkUrl
          linkText
          items {
            id
            description
            image {
              id
              title
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulFeaturedProject(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          id
          title
          linkUrl
          featuredImage {
            id
            title
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulTestimonial(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          id
          name
          company
          testimonial {
            testimonial
          }
        }
      }
    }

    serviceImage: file(relativePath: { eq: "service-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 542) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    residentialImage: file(relativePath: { eq: "residential-service.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    commercialImage: file(relativePath: { eq: "commercial-service.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    specialImage: file(relativePath: { eq: "special-finishes.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
