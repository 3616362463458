/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Img from 'gatsby-image';

class HomepageServices extends Component {
  render() {
    const {
      serviceImage,
      residentialImage,
      commercialImage,
      specialImage,
    } = this.props;

    // Update images
    const RevealFirstImage = () => {
      if (typeof document !== 'undefined') {
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('wipe-img');
      }
    };

    const RevealSecondImage = () => {
      if (typeof document !== 'undefined') {
        const overlay = document.querySelector('.overlay-2');
        overlay.classList.add('wipe-img-left');
      }
    };

    const RevealThirdImage = () => {
      if (typeof document !== 'undefined') {
        const overlay = document.querySelector('.overlay-3');
        overlay.classList.add('wipe-img');
      }
    };

    const RevealFourthImage = () => {
      if (typeof document !== 'undefined') {
        const overlay = document.querySelector('.overlay-4');
        overlay.classList.add('wipe-img-left');
      }
    };

    return (
      <div className="homepage-services">
        <div className="container">
          <div className="row">
            <div className="sm:col-12 md:col-5 service-col service-content">
              <Fade exit={false}>
                <p className="content-top">
                  With over 30 years experience, Otago Painting Solutions have a
                  proud history in Dunedin and throughout Otago. Our expert team
                  can work of any size.
                </p>
                <p>
                  Expert attention to detail has earned us a solid reputation
                  with many testimonials to prove it, from the smallest of home
                  painting and decorating to the largest commercial jobs in
                  town.
                </p>
                <AniLink fade to="/about" className="btn btn-dark">
                  Find out more
                </AniLink>
              </Fade>
            </div>
            <div className="sm:col-12 md:col-6 md:offset-1 service-col flex">
              <Img
                className="fill-x"
                fluid={{
                  ...serviceImage.childImageSharp.fluid,
                  aspectRatio: 2 / 1,
                }}
                alt="service"
              />
              <div className="overlay">
                <Reveal onReveal={() => RevealFirstImage()} />
              </div>
            </div>
          </div>
          <div className="row reverse">
            <div className="sm:col-12 md:col-5 service-col flex">
              <Img
                className="fill-x"
                fluid={{
                  ...residentialImage.childImageSharp.fluid,
                  aspectRatio: 2 / 1,
                }}
                alt="service"
              />
              <div className="overlay overlay-2">
                <Reveal onReveal={() => RevealSecondImage()} />
              </div>
            </div>
            <Fade exit={false}>
              <div className="sm:col-12 md:col-6 md:offset-1 service-col">
                <h4 className="content-top">Residential</h4>
                <p>
                  An exceptional paint job is essential to give your beautiful
                  home the fine finish it deserves. We’ve worked on some of the
                  most stunning homes across Otago. Whether you’re building a
                  new home, completely renovating, or just updating a room or
                  two, we can tailor a solution to suit you.
                </p>
                <p>
                  New homes to character villas and everything in between.
                  Specialising in both exterior and interior painting, including
                  Roof Painting, Gib Stopping, and Wallpaper Hanging. We pride
                  ourselves on getting the job done on time, within budget, to a
                  high-quality finish.
                </p>
                <AniLink
                  fade
                  to="/services#residential"
                  className="btn btn-dark"
                >
                  Find out more
                </AniLink>
              </div>
            </Fade>
          </div>
          <div className="row">
            <Fade exit={false}>
              <div className="sm:col-12 md:col-6 service-col service-content">
                <h4 className="content-top">Commerical</h4>
                <p>
                  Our decorating team is Site Safe certified, and well
                  experienced in both small and large quality commercial
                  projects. We can work with you team to ensure any new builds
                  or renovation projects are completed on schedule and to
                  budget.
                </p>
                <p>
                  We have worked on a wide range of commercial projects
                  throughout Otago and have the industry skills and knowledge to
                  work with you in completing your project to the highest
                  standard.
                </p>
                <AniLink
                  fade
                  to="/services#commercial"
                  className="btn btn-dark"
                >
                  Find out more
                </AniLink>
              </div>
            </Fade>
            <div className="sm:col-12 md:col-5 md:offset-1 service-col flex">
              <Img
                className="fill-x"
                fluid={{
                  ...commercialImage.childImageSharp.fluid,
                  aspectRatio: 2 / 1,
                }}
                alt="service"
              />
              <div className="overlay overlay-3">
                <Reveal onReveal={() => RevealThirdImage()} />
              </div>
            </div>
          </div>
          <div className="row reverse">
            <div className="sm:col-12 md:col-5 service-col flex">
              <Img
                className="fill-x"
                fluid={{
                  ...specialImage.childImageSharp.fluid,
                  aspectRatio: 2 / 1,
                }}
                alt="service"
              />
              <div className="overlay overlay-4">
                <Reveal onReveal={() => RevealFourthImage()} />
              </div>
            </div>
            <Fade exit={false}>
              <div className="sm:col-12 md:col-6 md:offset-1 service-col">
                <h4 className="content-top">Special Finishes</h4>
                <p>
                  Sometimes a solution will require more than a coat of paint.
                  We offer a number of specialist finishes from Acoustic Wall
                  Panels to reduce noise in an office or classroom, to TrikBrik
                  a lightweight wall panel that look like rustic loft bricks.
                </p>
                <p>
                  Our skilled team can offer a bespoke solution to your creative
                  space, a splash of colour to your notice boards or just
                  something a little different.
                </p>
                <AniLink
                  fade
                  to="/services#special-finishes"
                  className="btn btn-dark"
                >
                  Find out more
                </AniLink>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageServices;
