import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'pure-react-carousel';

class BannerItem extends Component {
  render() {
    const { description, id, image } = this.props;

    return (
      <Slide index={0}>
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img
              src={image.fluid.src}
              alt={image.title}
              className="fill-x"
              id={id}
            />
            <p className="img-caption">{description}</p>
          </div>
        </div>
      </Slide>
    );
  }
}

export default BannerItem;

BannerItem.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    image: PropTypes.object,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
  }),
};
