import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import FeaturedItem from './featured-item';
import right from '../assets/icons/right-chevron.svg';
import left from '../assets/icons/left-chevron.svg';

class FeaturedProjects extends Component {
  render() {
    const { items } = this.props;
    const numberofSlides = items.length;

    return (
      <Fade>
        <div className="featured-projects">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="img-carousel">
                  <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    totalSlides={numberofSlides}
                    dragEnabled={false}
                    touchEnabled={false}
                  >
                    <Slider className="slider-element">
                      {items.map(({ node }, i) => (
                        <FeaturedItem node={node} key={node.id} position={i} />
                      ))}
                    </Slider>
                    <div className="indicators">
                      <ButtonBack className="prev">
                        <img src={left} alt="Previous slide" width="10px" />
                      </ButtonBack>
                      <ButtonNext className="next">
                        <img src={right} alt="Next slide" width="10px" />
                      </ButtonNext>
                    </div>
                  </CarouselProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default FeaturedProjects;

FeaturedProjects.propTypes = {
  items: PropTypes.array.isRequired,
};
