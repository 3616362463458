import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import TestimonialItem from './testimonial-item';

import right from '../assets/icons/right-chevron.svg';
import left from '../assets/icons/left-chevron.svg';

class Testimonials extends Component {
  constructor() {
    super();
    this.state = {
      windowWidth: 0,
    };

    this.calculateWindowWidth = this.calculateWindowWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWindowWidth();
    window.addEventListener('resize', this.calculateWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateWindowWidth);
  }

  calculateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const node = this.props;
    const { windowWidth } = this.state;

    return (
      <Fade>
        <div className="testimonials-block">
          <div className="container">
            <CarouselProvider
              naturalSlideWidth={672}
              naturalSlideHeight={400}
              dragEnabled={false}
              touchEnabled={false}
              totalSlides={node.items.length}
            >
              <div className="row">
                <div className="col-12 lg:col-5 no-gutters">
                  <div className="testimonial-description">
                    <h1 className="m0 mb4">Our Happy Clients</h1>
                    {windowWidth > 1024 ? (
                      <p>
                        See what our clients have to say about our amazing work
                      </p>
                    ) : (
                      <Slider className="slider-element" classNameTray="tray">
                        {node.items.map(item => {
                          return (
                            <TestimonialItem
                              node={item.node}
                              key={item.node.id}
                            />
                          );
                        })}
                      </Slider>
                    )}
                    {node.items.length > 1 && (
                      <div className="sm:mt10 mt4">
                        <ButtonBack className="prev">
                          <img src={left} alt="Previous slide" />
                        </ButtonBack>
                        <ButtonNext className="next">
                          <img src={right} alt="Next slide" />
                        </ButtonNext>
                      </div>
                    )}
                  </div>
                </div>
                {windowWidth > 1024 && (
                  <div className="col-12 lg:col-7 no-gutters">
                    <Slider className="slider-element" classNameTray="tray">
                      {node.items.map(item => {
                        return (
                          <TestimonialItem
                            node={item.node}
                            key={item.node.id}
                          />
                        );
                      })}
                    </Slider>
                  </div>
                )}
              </div>
            </CarouselProvider>
          </div>
        </div>
      </Fade>
    );
  }
}

export default Testimonials;
